import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonSize, ButtonType } from '@app/types'

interface ButtonProps {
  id?: string;
  className?: string
  size?: ButtonSize
  to?: string
  href?: string
  fluid?: boolean
  fluid20?: boolean
  fluid30?: boolean
  fluid40?: boolean
  fluid50?: boolean
  fluid80?: boolean
  type?: ButtonType
  disabled?: boolean;
  inactive?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void;
  textColor?: string;
  border?: string;
  borderRadius?: string;
}

const Button: React.FC<ButtonProps> = props => {

  const classes = [
    'Button',
    'size-' + (props.size ? props.size : 'normal'),
    'type-' + (props.type ? props.type : 'normal'),
    props.className,
    props.fluid ? 'fluid' : '',
    props.fluid20 ? 'fluid30' : '',
    props.fluid30 ? 'fluid30' : '',
    props.fluid40 ? 'fluid40' : '',
    props.fluid50 ? 'fluid50' : '',
    props.fluid80 ? 'fluid80' : '',
    props.disabled ? 'disabled' : '',
    props.inactive ? 'inactive' : '',
  ].join(' ')

  return <ButtonStyled
    id={props.id}
    as={props.href ? 'a' : props.to ? Link : 'button'}
    to={props.disabled ? '' : props.to}
    href={props.href}
    onClick={props.onClick}
    className={classes}
    disabled={props.disabled}
    color={props.textColor}
    border={props.border}
    borderRadius={props.borderRadius}
  >
    {props.children}
  </ButtonStyled>
}

const ButtonStyled = styled.button<{color: string, border?: string, borderRadius: string}>`

  border: ${({border}) => border ? border : 'none'};
  cursor: pointer;
  border-radius: ${({borderRadius}) => borderRadius ? borderRadius : '5px'};
  color:${({color}) => color ? color : 'var(--font-color-primary)'} ;
  background-color: #F4F5F8;
  text-decoration: none;
  font-size: var(--font-size-normal);

  &.size-xsmall {
    padding: 0.2rem;
    width: 2rem;
  }
  &.size-small {
  }
  &.size-normal {
    padding: 0.5rem;
  }
  &.size-normal-nopadding {
    padding: 0;
  }
  &.size-normal-left-margin {
    margin-left: 0.5rem;
    padding: 0;

  }
  &.size-mid-normal {
    padding: 0.5rem;
  }
  &.size-big {
    font-size: var(--font-size-big);
    padding: 1rem 0.5rem;
    font-weight: bold;
  }

  &.type-primary {
    background-color: var(--color-brand);
  }

  &.type-primary-dark-refreshed {
    background-color: var(--color-brand-dark-new);
  }
  &.type-button-color {
    background-color: var(--r-color-button);
  }

  &.type-primary-dark {
    background-color: var(--color-brand-dark);
  }
 
  &.type-primary-pale {
    background-color: var(--color-brand-pale);
  }

  &.type-dark {
    background-color: #4D4D4D;
  }

  &.type-white {
    background-color: white;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 -1px 6px rgba(0, 0, 0, 0.1);
  }

  &.type-transparent {
    background-color: transparent;
  }

  &.type-gray-C {
    background-color: var(--r-color-gray-C);
  }

  &.type-warning {
    background-color: var(--color-warning);
    color: var(--font-color-inverted);
  }
  &.type-primary-warning {
    background-color: var( --color-brand);
    color: var(--color-warning);
  }

  &.fluid {
    width: 100%;
  }

  &.fluid20 {
    width: 20%;
  }
  &.fluid30 {
    width: 30%;
  }
  &.fluid40 {
    width: 40%;
  }

  &.fluid50 {
    width: 50%;
  }

  &.fluid80 {
    width: 80%;
  }

  &.disabled {
    opacity: .3;
  }

  &.inactive {
    background-color: var(--r-color-gray-inactive);
  }

  &.channelList {
      width: 20%;
      color: white
  }
`

export default Button
